import React, { useEffect, useState, useContext } from 'react';
import IdentityContext from '../auth/IdentityContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, FormControl, InputLabel, MenuItem, NativeSelect, Select, SelectChangeEvent } from '@mui/material';

import Price from '../widgets/shop/Price';
import API from '../../API';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import IfAuthorized from '../conditions/IfAuthorized';

const IncomeDetailsPage = () => {
  const {identity, setIdentity} = useContext(IdentityContext);
  const [yearlySummary, setYearlySummary] = useState<any[]>([]);
  const [thisYearMonthlySummary, setThisYearMonthlySummary] = useState<any[]>([]);
  const [lastYearMonthlySummary, setLastYearMonthlySummary] = useState<any[]>([]);
  const [weeklySummary, setWeeklySummary] = useState<any>({});
  const [location, setLocation] = useState<string>("All");

  const now = new Date();
  const thisYear = now.getFullYear();
  const lastYear = thisYear - 1;

  const FIRST_YEAR = 2019;

  useEffect(() => {
    const doFetchTransactions = async() => {
      const myTransactions = await API.getYearlyTransactionSummary(location, identity?.accessToken);
      if (myTransactions) {
        setYearlySummary(myTransactions);
      }

      const newSummary: Record<number, any[]> = {};
      for (let year = FIRST_YEAR; year <= thisYear; year++) {
        const lastYearWeeklyTransactions = await API.getWeeklyTransactionSummary(location, year, identity?.accessToken);
        if (lastYearWeeklyTransactions) {
          newSummary[year] = lastYearWeeklyTransactions;
        }  
      }
      setWeeklySummary(newSummary);

      const thisYearTransactions = await API.getMonthlyTransactionSummary(location, thisYear, identity?.accessToken);
      if (thisYearTransactions) {
        setThisYearMonthlySummary(thisYearTransactions);
      }
      const lastYearTransactions = await API.getMonthlyTransactionSummary(location, lastYear, identity?.accessToken);
      if (lastYearTransactions) {
        setLastYearMonthlySummary(lastYearTransactions);
      }
    }

    doFetchTransactions();
  }, [location]);

  const handleLocationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLocation(event.target.value as string);
  }

  const yearTotalMap: Record<string, number> = {};
  const yearSubtotalMap: Record<string, number> = {};

  const monthTotalMap: Record<number, Record<number, number>> = {};
  const monthSubtotalMap: Record<number, Record<number, number>> = {};

  const weekTotalMap: Record<number, Record<string, number>[]> = {};

  const yearNames = Object.keys(weeklySummary);
  console.log("WEEKLY STUFF: " + yearNames);

  yearNames.forEach(yearName => {
    console.log("  * SUMMARY FOR YEAR: " + yearName);
    const weeklySummaryForYear = weeklySummary[yearName];

    weekTotalMap[Number(yearName)] = [];

    for (let i = 0; i < weeklySummaryForYear.length; i++) {
      console.log("    - " + JSON.stringify(weeklySummaryForYear[i]));
      const transaction = weeklySummaryForYear[i];
      const subtotal = transaction.subtotal;
      const total = transaction.total;
      const week = transaction._id;
 
      weekTotalMap[Number(yearName)].push({
        "subtotal": subtotal,
        "total": total
      });
    }  
  });
  
  for (let i = 1; i <= 12; i++) {
    monthTotalMap[i] = {};
    monthSubtotalMap[i] = {};
  }


  for (let i = 0; i < yearlySummary.length; i++) {
    const transaction = yearlySummary[i];
    const subtotal = transaction.subtotal;
    const total = transaction.total;
    const year = transaction._id;

    yearTotalMap[year] = yearTotalMap[year] ? yearTotalMap[year] + total : total;
    yearSubtotalMap[year] = yearSubtotalMap[year] ? yearSubtotalMap[year] + subtotal : subtotal;
  }

  for (let i = 1; i <= 12; i++) {
    monthTotalMap[i] = {};
    monthSubtotalMap[i] = {};
  }

  for (let i = 0; i < 12; i++) {
    {
      const transaction = thisYearMonthlySummary[i];
      if (transaction) {
        const month = +(transaction._id);
        const total = transaction.total;
        const subtotal = transaction.subtotal;

        monthTotalMap[month][thisYear] = total;
        monthSubtotalMap[month][thisYear] = subtotal;
      }
    }
    {
      const transaction = lastYearMonthlySummary[i];
      if (transaction) {
        const month = +(transaction._id);
        const total = transaction.total;
        const subtotal = transaction.subtotal;

        monthTotalMap[month][lastYear] = total;
        monthSubtotalMap[month][lastYear] = subtotal;
      }
    }
  }

  const weeklyChartData: Record<string, number>[] = [];
  const yearlyTotal: Record<number, number> = {};
  for (let year = FIRST_YEAR; year <= thisYear; year++) {
    if (weeklySummary[year]) {
      weeklySummary[year].sort((a: any, b: any) => { return a._id - b._id } );
    }
    yearlyTotal[year] = 0;
  }

  for (let i = 0; i < 54; i++) {
    const week: Record<string, number> = {};
    week['Week'] = i + 1;

    for (let year = FIRST_YEAR; year <= thisYear; year++) {
      if (weeklySummary[year]) {
        const mySummary = weeklySummary[year];
        if (mySummary.length > i) {
          week[year] = mySummary[i].subtotal / 100;
          yearlyTotal[year] += mySummary[i].subtotal / 100;
          week[year + ' (Total)'] = yearlyTotal[year];
          week[year + ' (Customers)'] = mySummary[i].count;
        }
      }
    }
    weeklyChartData.push(week);
  }

  function getWeeklyData(year: number): React.ReactNode {
    console.log("YEAR: " + year);
    if (!weekTotalMap[year]) {
      return <></>;
    }
    return (
      <Card>
        <h1>{year}</h1>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Week</TableCell>
                <TableCell>Subtotal</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from(Array(52).keys()).map((week0) => {
                const week = week0 + 1;
                if (!weekTotalMap[year][week0]) {
                  return <TableRow></TableRow>;
                }
                return (
                <TableRow>
                  <TableCell>{week}</TableCell>
                  <TableCell>
                    <Price price={weekTotalMap[year][week0].subtotal}/>
                  </TableCell>
                  <TableCell>
                    <Price price={weekTotalMap[year][week0].total}/>
                  </TableCell>
                </TableRow>);
            })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    )
}

  return (
    <div className="ags-main">
      <div className="ags-body">
        <FormControl fullWidth>
          <NativeSelect
            defaultValue="bridgewater"
            id="demo-simple-select"
            value={location}
            inputProps={{
              name: 'Location',
              id: 'locationSelector',
            }}
            onChange={handleLocationChange}
          >
            <option value="All">All</option>
            <option value="bridgewater">Bridgewater</option>
            <option value="bongbus">Bong Bus</option>
            <option value="liverpool">Liverpool</option>
            <IfAuthorized role='florist'>
              <option value="florist">Florist</option>
            </IfAuthorized>
          </NativeSelect>
        </FormControl>
{/*           
          <Card className='income-weekly-summary'>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={300}
                data={weeklyChartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Week" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="2024" stroke="#ffb700" strokeWidth="4" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="2023" stroke="#8800cc" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="2022" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="2021" stroke="#82ca9d" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="2020" stroke="#d88888" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="2019" stroke="#999999" activeDot={{ r: 8 }} />
                <ReferenceLine x="5" stroke="green" strokeDasharray="3 3" strokeWidth="3"/>
                <ReferenceLine x="7" stroke="red" label={{position: "insideTop", value: "Valentines"}} strokeDasharray="3 3"/>
                <ReferenceLine x="16" stroke="blue" label={{position: "insideTop", value: "420"}} strokeDasharray="3 3"/>
                <ReferenceLine x="20" stroke="violet" label={{position: "insideTop", value: "Mothers Day"}} strokeDasharray="3 3"/>
                <ReferenceLine x="26" stroke="blue" label={{position: "insideTop", value: "Privateers"}} strokeDasharray="3 3"/>
                <ReferenceLine x="30" stroke="blue" label={{position: "insideTop", value: "Expo"}} strokeDasharray="3 3"/>
                <ReferenceLine x="52" stroke="red" label={{position: "insideTop", value: "Christmas"}} strokeDasharray="3 3"/>
              </LineChart>
            </ResponsiveContainer>
          </Card> */}

        {getWeeklyData(2019)}
        {getWeeklyData(2020)}
        {getWeeklyData(2021)}
        {getWeeklyData(2022)}
        {getWeeklyData(2023)}
        {getWeeklyData(2024)}
      </div>
    </div>
  )
}

export default IncomeDetailsPage