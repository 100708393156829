import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Header from './components/widgets/Header';
import Footer from './components/widgets/Footer';
import Store from './components/pages/Store';
import CategoryContext, { CategoryState } from './contexts/CategoryContext'
import ConfigContext, { ConfigState } from './contexts/ConfigContext'
import About from "./components/pages/About";
import ExportCsv from './components/pages/ExportCsv';
import MemberPage from "./components/pages/MemberPage";
import NewItemPage from "./components/pages/NewItemPage";
import IdentityTemplate from "./components/auth/IdentityTemplate";
import IfAuthorized from "./components/conditions/IfAuthorized";
import InvoicePage from "./components/pages/InvoicePage";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import CategoryDrawer from "./components/widgets/category/CategoryDrawer";
import {Helmet} from "react-helmet";
import IncompletePage from "./components/pages/IncompletePage";
import OrphanPage from "./components/pages/OrphanPage";
import InventoryPage from "./components/pages/InventoryPage";
import IncomePage from "./components/pages/IncomePage";
import ShippingEstimatePage from "./components/pages/admin/ShippingEstimatePage";
import IfNotAuthorized from "./components/conditions/IfNotAuthorized";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import API from "./API";
import InventoryReport from "./components/pages/reports/InventoryReport";
import IncomeDetailsPage from "./components/pages/IncomeDetailsPage";
import AnalysisPage from "./components/pages/AnalysisPage";

// Lazy load these things for security -- non authorized users will not get the code
const OrdersPage = React.lazy(() => import("./components/pages/OrdersPage"));
const TransactionPage = React.lazy(() => import("./components/pages/TransactionPage"));


const App = () => {
  const [deptOpen, setDeptOpen] = React.useState(false);
  const [areFiltersOpen, setAreFiltersOpen] = React.useState<boolean>(true);

  const [ config, setConfig ] = React.useState<Record<string, any>>({});
  
  const [category, setCategoryState] = useState<CategoryState>({
    categories: []
  });

  const fetchCategories = async () => {
    try {
      // const allRoots = await getCategories();
      // const roots = allRoots.filter(applyRootFilter);
      // const myCategories = roots.flatMap(category => {
      //   return category.children;
      // });
      const roots = await API.getCategories();

      setCategoryState({
        categories: roots
      });
    }
    catch (err: any) {
      console.error(err.message);
    }
  }

  /**
   * Load categories on startup
   */
  useEffect(() => {  
    fetchCategories();
  }, []);

  /**
   * Request refresh
   */
  const doCategoryRefresh = () => {
    fetchCategories();
  }

  useEffect(() => {
    if (config?.general?.product_name) {
      document.title = config.general.product_name;
    }
  }, [config?.general?.product_name]);

  const subtitle = config?.general?.product_subtext;
  const title = subtitle ? config?.general?.product_name + " - " + config?.general?.product_subtext : config?.general?.product_name;

  return (
    <DndProvider backend={HTML5Backend}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={config?.general?.description}/>
      </Helmet>
      <ConfigContext.Provider value={{ config, setConfig }}>
        <Router>
            <IdentityTemplate>
              <CategoryContext.Provider value={{ category, setCategoryState, doCategoryRefresh }}>
                <main className='App'>
                  <Suspense fallback={<div>Loading...</div>}>
                  
                    <Header
                      areFiltersOpen={areFiltersOpen}
                      setAreFiltersOpen={setAreFiltersOpen}
                      deptOpen={deptOpen}
                      setDeptOpen={setDeptOpen}
                      />
                    <div className='app-body'>
                      <IfAuthorized role='editor'>
                        <CategoryDrawer deptOpen={deptOpen} setDeptOpen={setDeptOpen}/>
                        {/* A <Switch> looks through its children <Route>s and
                          renders the first one that matches the current URL. */}
                          <Switch>
                            <Redirect from="//*" to="/*" />
                            <Redirect from="*//*" to="*/*" />

                            <Route path="/admin/orders/:dbName/:orderId">
                              <InvoicePage />
                            </Route>
                            <Route path="/admin/orders">
                              <OrdersPage />
                            </Route>
                            <Route path="/admin/transactions">
                              <TransactionPage />
                            </Route>
                            <Route path="/admin/incomplete">
                              <IncompletePage />
                            </Route>
                            <Route path="/admin/income">
                              <IncomePage />
                            </Route>
                            <Route path="/admin/analysis">
                              <AnalysisPage />
                            </Route>
                            <Route path="/admin/income-details">
                              <IncomeDetailsPage />
                            </Route>
                            <Route path="/admin/orphans">
                              <OrphanPage />
                            </Route>
                            <Route path="/admin/shipping">
                              <ShippingEstimatePage />
                            </Route>
                            <Route path="/admin/inventory">
                              <InventoryPage />
                            </Route>
                            <Route path="/admin/reports/inventory">
                              <InventoryReport />
                            </Route>
                            <Route path="/about">
                              <About />
                            </Route>
                            <Route path="/category/:group/:categoryId">
                              <Store setAreFiltersOpen={setAreFiltersOpen} areFiltersOpen={areFiltersOpen} />
                            </Route>
                            <Route path="/category/:categoryId">
                              <Store setAreFiltersOpen={setAreFiltersOpen} areFiltersOpen={areFiltersOpen} />
                            </Route>
                            <Route path="/category">
                              <Store setAreFiltersOpen={setAreFiltersOpen} areFiltersOpen={areFiltersOpen} />
                            </Route>
                            <Route path="/search/:searchText">
                              <Store setAreFiltersOpen={setAreFiltersOpen} areFiltersOpen={areFiltersOpen} />
                            </Route>
                            <Route path="/search/:searchText">
                              <Store setAreFiltersOpen={setAreFiltersOpen} areFiltersOpen={areFiltersOpen} />
                            </Route>
                            <Route path="/search/:searchText">
                              <Store setAreFiltersOpen={setAreFiltersOpen} areFiltersOpen={areFiltersOpen} />
                            </Route>
                            <Route path="/export/csv">
                              <ExportCsv />
                            </Route>
                            <Route path="/item/new/:itemId">
                              <NewItemPage />
                            </Route>
                            <Route path="/item/new">
                              <NewItemPage />
                            </Route>
                            <Route path="/item/:itemId">
                              <Store setAreFiltersOpen={setAreFiltersOpen} areFiltersOpen={areFiltersOpen} />
                            </Route>
                            <Route path="/members">
                              <MemberPage/>
                            </Route>
                            <Route path="/">
                              <Store setAreFiltersOpen={setAreFiltersOpen} areFiltersOpen={areFiltersOpen} />
                            </Route>
                          </Switch>
                      </IfAuthorized>
                      <IfNotAuthorized role='editor'>
                        <Alert severity="info">
                          <AlertTitle>LOGIN REQUIRED</AlertTitle>
                          Login required to access this application
                        </Alert>                        
                        </IfNotAuthorized>
                    </div>
                    <Footer />
                  </Suspense>
                </main>
              </CategoryContext.Provider>
            </IdentityTemplate>
        </Router>
      </ConfigContext.Provider>
    </DndProvider>
  )
}

export default App